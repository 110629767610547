import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useSelector, useStore } from "react-redux";

import DashboardAuth from "../modules/authentification/components/index";
import Designer from "../modules/designer/Designer";
import Installer from "../modules/installer/Installer";
import Monitor from "../modules/monitor/Monitor";
import NotFound from "../modules/notfound/NotFound";
import React from "react";
import Dashboard from "../modules/monitor/components/dashboard";
import Issues from "../modules/monitor/components/issues";
import DebugIndex from "../modules/monitor/components/debug";
import Performance from "../modules/monitor/components/performances";

import { LogsReader } from "../modules/monitor/components/LogsReader";
import RamChart from "../modules/monitor/components/dashboard/ramChart";
import { useMonitorSocket } from "../modules/customHooks.js/useMonitorSocket";
import Configuration from "../modules/monitor/components/configuration";

function Home() {
  let store = useStore();

  // const componentData = useMonitorSocket();

  store.dispatch({
    type: "checkMaximoConfig",
  });
  const configStatus = useSelector(
    (state) => state?.entities?.dashboard?.availableConfig
  );
  const installStatus = useSelector(
    (state) => state?.entities?.dashboard?.completedInstallation
  );

  const isLoggedin = useSelector(
    (state) => state?.entities?.dashboard?.loggedIn
  );

  store.dispatch({
    type: "explorer/reset",
  });
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DashboardAuth />} />

        <Route
          path="/monitor"
          element={
            isLoggedin && configStatus ? <Monitor /> : <Navigate to="/" />
          }
        ></Route>
        <Route
          path="/monitor/Dashboard"
          element={
            isLoggedin && configStatus ? (
              <Dashboard
              // data={componentData}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/monitor/Issues"
          element={
            isLoggedin && configStatus ? (
              <Issues
              // data={componentData}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/monitor/Performances"
          element={
            isLoggedin && configStatus ? (
              <Performance
              // data={componentData}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/monitor/Configuration"
          element={
            isLoggedin && configStatus ? <Configuration /> : <Navigate to="/" />
          }
        />
        <Route
          path="/monitor/Debug"
          element={
            isLoggedin && configStatus ? (
              <DebugIndex
              // data={componentData}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/monitor/LogsReader"
          element={
            isLoggedin && configStatus ? (
              <LogsReader
              // data={componentData}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/monitor/Stats"
          element={
            isLoggedin && configStatus ? <RamChart /> : <Navigate to="/" />
          }
        />
        <Route
          path="/designer"
          element={
            isLoggedin && configStatus && installStatus ? (
              <Designer />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/installer"
          element={
            isLoggedin && configStatus ? <Installer /> : <Navigate to="/" />
          }
        />

        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Home;

function removeNulls(obj) {
  if (typeof obj !== "object" || !obj) {
    return {};
  }
  var isArray = obj instanceof Array;

  for (var k in obj) {
    if (!obj[k] && isArray) obj.splice(k, 1);
    else if (typeof obj[k] == "object") removeNulls(obj[k]);
  }

  return obj;
}

export default removeNulls;

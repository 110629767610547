import { scriptInstallation } from "../../../../../services";

function installScript(
  setScript,
  setErrorMessage,
  setIsRetrying,
  openDialog,
  scriptName,
  script
) {
  setIsRetrying(true);
  scriptInstallation(scriptName)
    .then((response) => {
      if (
        !response?.success ||
        response?.content?.status.toUpperCase() === "FAILURE"
      ) {
        openDialog("error");
        setErrorMessage(
          response?.message !== ""
            ? response?.message
            : response?.content?.message
        );
        setIsRetrying(false);
      } else {
        let autoScript = script;
        autoScript = { ...script, status: true, loggerStatus: true };
        setScript(autoScript);
        setIsRetrying(false);
      }
    })
    .catch((error) => {
      openDialog("error");
      setErrorMessage(error.message);
      setIsRetrying(false);
    });
}

export default installScript;

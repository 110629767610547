import "./index.scss";

import React, { useCallback, useEffect, useState } from "react";

import IssueFilter from "./filter";
import IssueInfoCard from "./infoCard";
import IssueBody from "./list-items";
import Sidebar from "../monitor-sidebar";
import DashboardApp from "../../../dashboard/Dashboard";
import getIssuesData from "./modules/getIssuesData";
import deleteAllIssuesData from "./modules/deleteAllIssuesData";
import { useToasts } from "react-toast-notifications";

function Issues() {
  const { addToast } = useToasts();

  const [issuesData, setIssuesData] = useState([]);
  const [filtredData, setFiltredData] = useState([]);

  const [filterIcons, setFilterIcons] = useState({
    issue: "ascending",
    source: "descending",
    date: "ascending",
  });
  const getIssues = useCallback(async () => {
    try {
      const transactionsData = await getIssuesData();
      const result = transactionsData?.content?.result?.map((item) => {
        const dateObj = new Date(item.createdAt);
        const formattedDate =
          dateObj.toLocaleTimeString(undefined, {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
          }) +
          " - " +
          dateObj.toLocaleDateString(undefined, {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });

        return {
          level: item?.level,
          service: item?.service,
          message: item?.message,
          api: item?.api,
          user: item?.user ?? "admin",
          createdAt: formattedDate,
          date: dateObj,
        };
      });
      setIssuesData(result);
      setFiltredData(result);
    } catch (error) {
      addToast("error while getting Issues", {
        appearance: "error",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    }
  }, []);
  function filterSearch(e) {
    const filteredData = issuesData?.filter((item) => {
      let exist = false;
      Object.keys(item)?.forEach((label) => {
        if (
          item?.[label]
            ?.toString()
            ?.toUpperCase()
            ?.indexOf(e?.target?.value.toString()?.toUpperCase()) > -1
        ) {
          exist = true;
        }
      });
      return exist;
    });
    setFiltredData(filteredData);
  }
  function filterSearchByDate(e) {
    const filteredData = issuesData?.filter((item) => {
      let exist = false;
      Object.keys(item).forEach((label) => {
        if (
          label == "createdAt" &&
          item[label]
            ?.toString()
            ?.toUpperCase()
            ?.indexOf(e?.target?.value?.toString()?.toUpperCase()) > -1
        ) {
          exist = true;
        }
      });
      return exist;
    });
    setFiltredData(filteredData);
  }
  function filterSearchByUser(e) {
    const filteredData = issuesData?.filter((item) => {
      let exist = false;
      if (e?.target?.value == "") {
        exist = true;
        return exist;
      }
      Object.keys(item).forEach((label) => {
        if (
          label == "user" &&
          item[label]
            ?.toString()
            ?.toUpperCase()
            ?.indexOf(e?.target?.value?.toString()?.toUpperCase()) > -1
        ) {
          exist = true;
        }
      });
      return exist;
    });
    setFiltredData(filteredData);
  }
  const handleSortDate = () => {
    if (filterIcons?.date === "ascending") {
      const dataToSort =
        filtredData?.length == issuesData?.length ? issuesData : filtredData;
      let sortedDate = dataToSort?.sort((a, b) => a?.date - b?.date);
      setFiltredData(sortedDate);
      setFilterIcons({
        ...filterIcons,
        date: "descending",
      });
    } else {
      const dataToSort =
        filtredData?.length == issuesData?.length ? issuesData : filtredData;

      let sortedDate = dataToSort?.sort((a, b) => b?.date - a?.date);
      setFiltredData(sortedDate);
      setFilterIcons({
        ...filterIcons,
        date: "ascending",
      });
    }
  };
  const deleteAllIssues = async () => {
    try {
      const DeleteResponse = await deleteAllIssuesData();
      if (DeleteResponse.content.status == "success") {
        setIssuesData([]);
        setFiltredData([]);
        addToast("Issues deleted successfully", {
          appearance: "success",
          autoDismissTimeout: 3000,
          autoDismiss: true,
          transitionDuration: 600,
        });
      } else {
        addToast("error while deleting Issues", {
          appearance: "error",
          autoDismissTimeout: 3000,
          autoDismiss: true,
          transitionDuration: 600,
        });
      }
    } catch (error) {
      addToast("error while deleting Issues", {
        appearance: "error",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    }
  };
  useEffect(() => {
    getIssues();
  }, []);
  return (
    <DashboardApp>
      <Sidebar>
        <div className=" px-3">
          <IssueInfoCard
            data={filtredData}
            deleteAllIssues={deleteAllIssues}
            refreshData={getIssues}
          />
        </div>
        <IssueFilter
          filterIcons={filterIcons}
          filterSearch={filterSearch}
          handleSortDate={handleSortDate}
          filterSearchByDate={filterSearchByDate}
          filterSearchByUser={filterSearchByUser}
        />
        {filtredData?.map((data, index) => (
          <div key={index}>
            <div id="IssueBody" className="container-fluid IssueBody">
              <div className="row">
                <div className="col-1 col-sm-1 col-md-1 text-right p-2 pt-3">
                  {/* <Icon color="red" icon={data.icon} /> */}
                </div>
                <div className="col-6 col-sm-6 col-md-6 p-2">
                  <IssueBody issue={data} />
                </div>
                <div className="col-2 col-sm-2 col-md-2 p-2">
                  <p className="bugserver">{data.user}</p>
                </div>
                <div className="col-2 col-sm-2 col-md-2 p-2">
                  <p className="bugdate">{data.createdAt}</p>
                </div>
                <div className="col-1 col-sm-1 col-md-1 p-2"></div>
              </div>
            </div>
          </div>
        ))}
      </Sidebar>
    </DashboardApp>
  );
}

export default Issues;
